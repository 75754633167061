import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [

  {
    id: 'welcome',
    title: 'Proceso de matrícula',
    type: 'item',
    icon: 'home',
    url: '/i/dashboard',
  },
  /*{
    id: 'payment-history',
    title: 'Historial de pagos',
    type: 'item',
    icon: 'account_balance_wallet',
    url: '/i/payment-history',
  },*/
  // {
  //   id: 'events-calendar',
  //   title: 'Calendario de eventos',
  //   type: 'item',
  //   icon: 'calendar_today',
  //   url: '/i/events-calendar',
  // },
  // {
  //   id: 'children-info',
  //   title: 'Información de hijos',
  //   type: 'item',
  //   icon: 'face',
  //   url: '/i/children-info',
  // },
  {
    id: 'payment-pension',
    title: 'Pensiones',
    type: 'item',
    icon: 'account_balance_wallet',
    url: '/i/payment-pension',
  },
  /*{
    id: 'badges',
    title: 'Insignias',
    type: 'item',
    icon: 'account_balance_wallet',
    url: '/i/badges',
  },*/
  {
    id: 'payment-extra',
    title: 'Actividades extracurriculares',
    type: 'item',
    icon: 'ballot',
    url: '/i/payment-extra',
  },
  {
    id: 'qualifications',
    title: 'Boletines escolares',
    type: 'item',
    icon: 'ballot',
    url: '/i/qualifications',
  },
  {
    id: 'badges',
    title: 'Insignias',
    type: 'item',
    icon: 'ballot',
    url: '/i/badges',
  },
  {
    id: 'omnichannel',
    title: 'Omnicanalidad',
    type: 'item',
    icon: 'ballot',
    url: '/i/omnichannel',
  },
  // {
  //   id: 'landing-kit',
  //   title: 'Landing Kit',
  //   type: 'item',
  //   icon: 'today',
  //   url: '/i/landing-kit',
  // },
];

