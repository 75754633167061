import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AdminService {
  constructor(private cookieService: CookieService, private http: HttpClient) { }

  /**
  * @author Adrian Avendano Concha
  * @date 22/03/2022
  */
  adminAuthentication() {
    return this.http.get(`${environment.API3}/AolAdminAuthFunction`)
  }

  /**
  * @author Adrian Avendano Concha
  * @date 22/03/2022
  */
  adminPermissions() {
    return this.http.get(`${environment.API3}/AolAdminRoleFunction`)
  }

  /**
  * @author Adrian Avendano Concha
  * @date 22/03/2022
  */
  adminPermissionsModule(module: string) {
    return this.http.get(`${environment.API_ENDPOINTS}/serviciosaol/AolAdminRoleFunction?module=${module}`)
  }

  /**
  * @update Adrian Avendano Concha
  * @date 22/03/2022
  */
  screeningPost(email: string): Observable<string> {
    let body = {
      email: email
    }
    return this.http.post<string>(`${environment.API_ENDPOINTS}/serviciosaol/reset_screening_opportunities`, body);
  }

  adminLogin(payload) {
    return this.http.post(`${environment.API}/admin/login`, payload)
  }

  createSuperAdmin(payload) {
    return this.http.post(`${environment.API}/admin/create-admin`, payload)
  }

  /**
  * @update Adrian Avendano Concha
  * @date 22/03/2022
  */
  resetPassword(email: string) {
    let body = {
      email: email
    }
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    return this.http.post(`${environment.API_ENDPOINTS}/serviciosaol/reset_user_password`, body, { headers: headers })
  }

  get token() {
    return this.cookieService.get(environment.ADMIN_COOKIE)
  }

  getParent(parentId: string) {
    return this.http.get(`${environment.API}/admin/parent/${parentId}`)
  }

  // getParents() {
  //   return this.http.get(`${environment.API}/admin/parents`)
  // }


  // export interface PaginatedCustomer extends Pagination {
  //   items: CloudCustomer[] | Customer[];
  // }

  // export interface Pagination {
  //   currentPage: number;
  //   totalItems: number;
  //   totalPages: number;
  // }

  public getParents(pageIndex?: number, pageSize?: number, search?: string): Observable<any> {
    // ?page=${pageIndex}&size=${pageSize}&search=${search}
    return this.http.get(`https://qw4xht9e7a.execute-api.us-east-1.amazonaws.com/Prod/parents`);
    // .pipe(map((paginatedCustomer: PaginatedCustomer) => {
    //    paginatedCustomer.items = (paginatedCustomer.items as CloudCustomer[]).map(c => Utils.parseCloudCustomer(c));
    //    return paginatedCustomer;
    // }));
    // .pipe(map((customers: CloudCustomer[]) => customers.map(c => Utils.parseCloudCustomer(c))));
  }

  getPayments() {
    return this.http.get(`${environment.API}/admin/payments`)
  }

  getPaymentsExcel() {
    return this.http.get(`assets/dummi/qua.json`)
  }

  getScreeningResult(childId: string) {
    return this.http.get(
      `${environment.API}/admin/child/${childId}/screening_results`,
    )
  }

  getScreeningResults() {
    return this.http.get(`${environment.API}/admin/screening_results`)
  }

  getStudent(studentId: string) {
    return this.http.get(`${environment.API}/admin/child/${studentId}`)
  }

  getStudents() {
    return this.http.get(`${environment.API}/admin/students`)
  }

  isAuhenticated() {
    return this.cookieService.get(environment.ADMIN_COOKIE) ? true : false
  }

  set token(jwt: string) {
    this.cookieService.set(environment.ADMIN_COOKIE, jwt, null, '/admin')
  }

  updateStudent(studentId: string, payload) {
    return this.http.put(
      `${environment.API}/admin/edit-child/${studentId}`,
      payload,
    )
  }


}
