import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';
import { IParentProfile } from '@server-responses/parent-profile.interface';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  constructor(
    private httpClient: HttpClient,
    private router: Router
  ) { }

  private profileUpdatedSource = new BehaviorSubject<IParentProfile>(null)

  public profileUpdated = this.profileUpdatedSource.asObservable()

  profileUpdate(profile: IParentProfile) {
    this.profileUpdatedSource.next(profile)
  }

  getComingEvents() {
    return this.httpClient.get(`${environment.API}/coming_events`)
  }

  getGooglePermissions() {
    return this.httpClient.post(`${environment.API}/oauth/requests/google-calendar`, '')
  }

  getPaymentStatus() {
    return this.httpClient.get(`${environment.API}/payments/status`)
  }

  getProfile() {
    //${environment.API}/parents/profile
    return this.httpClient
      .get(`${environment.API_ENDPOINTS}/aolservices/profile`)
      .pipe(
        tap(({ profile }: IParentProfile) => {
          if (!profile.parent.is_onboarding_completed) {
            this.router.navigateByUrl('/i/update-info')
          }
        })
      )
  }

  getScheduleStatus() {
    return this.httpClient.get(`${environment.API}/registration_status`)
  }

  googleRegistration(payload) {
    return this.httpClient.post(`${environment.API}/oauth/registrations/google-calendar`, payload)
  }
  getNotifications(parentId:string){
    return this.httpClient.get(`${environment.API}/notifications/parent/${parentId}`)
  }

}

