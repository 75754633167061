import { Injectable } from "@angular/core";
import { INACTIVITY_PERIOD } from "@constants/inactivity-period.constant";
import { AuthService } from "./auth.service";

@Injectable({providedIn: 'root'})
export class InactivityService {
  private pLeftAt: number
  private pThresholdMinutes: number = 15
  private pThresholdMs: number = this.pThresholdMinutes * 60 * 1000

  constructor(private authService: AuthService) {}

  set leftAt(leftAt: number) {
    this.pLeftAt = leftAt
  }

  get leftAt(): number {
    return this.pLeftAt
  }

  validateAndExpireSession() {
    if (!this.authService.token) return { state: INACTIVITY_PERIOD.SESSION_NON_EXISTING }

    const now = Date.now()
    const inactivityPeriod = now - this.pLeftAt

    if (inactivityPeriod > this.pThresholdMs) {
      this.authService.deleteSessionCookies()

      return { state: INACTIVITY_PERIOD.SESSION_EXPIRED }
    } else {
      return { state: INACTIVITY_PERIOD.SESSION_ACTIVE }
    }
  }
}