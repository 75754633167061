import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AdminGuard } from '@guards/admin-guard.service';

import { AdminComponent } from './admin.component';

const routes = [
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [AdminGuard],
    children: [
      { path: '', redirectTo: 'admin/login', pathMatch: 'full' },
      {
        path: 'create-users',
        loadChildren: () =>
          import('./admin-create-users/admin-create-users.module').then(
            (m) => m.AdminCreateUsersModule,
          ),
      },
      {
        path: 'parents',
        loadChildren: () =>
          import('./admin-parents/admin-parents.module').then(
            (m) => m.AdminParentsModule,
          ),
      },
      {
        path: 'payments',
        loadChildren: () =>
          import('./admin-payments/admin-payments.module').then(
            (m) => m.AdminPaymentsModule,
          ),
      },
      {
        path: 'students',
        loadChildren: () =>
          import('./admin-students/admin-students.module').then(
            (m) => m.AdminStudentsModule,
          ),
      },
      {
        path: 'landing-kit',
        loadChildren: () =>
          import('./inventory-landing-kit/inventory-landing-kit.module').then(
            (m) => m.InventoryLandingKitModule,
          ),
      },
      {
        path: 'school-grades',
        loadChildren: () =>
          import('./admin-school-grades/admin-school-grades.module').then(
            (m) => m.AdminSchoolGradesModule,
          ),
      },  
      {
        path: 'extracurriculares',
        loadChildren: () =>
        import('./admin-extracurriculares/admin-extracurriculares.module').then(
          (m) => m.AdminExtracurricularesModule,
        ),
       
      },
      {
        path: 'pension-services',
        loadChildren: () =>
          import('./admin-pensiones/admin-pensiones.module').then(
            (m) => m.AdminPensionesModule,
          ),
      },
      {
        path: 'print-documents',
        loadChildren: () =>
          import('./admin-print-documents/admin-print-documents.module').then(
            (m) => m.AdminPrintDocumentsModule,
          ),
      },
    ],
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminRoutingModule { }
