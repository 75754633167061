import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { INACTIVITY_PERIOD } from '@constants/inactivity-period.constant';
import { InactivityService } from '@services/inactivity.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent  {
  constructor(
    private inactivityService: InactivityService,
    private router: Router,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.handleInactivityPeriod()

    window.onfocus = (ev) => {
      this.handleInactivityPeriod()
    }

    window.onblur = (ev) => {
      this.inactivityService.leftAt = Date.now()
    }
  }

  private handleInactivityPeriod() {
    const { state } = this.inactivityService.validateAndExpireSession()

    if (state == INACTIVITY_PERIOD.SESSION_NON_EXISTING) return

    if (state == INACTIVITY_PERIOD.SESSION_EXPIRED) {
      this.toastr.info('Tu sesión ha expirado por inactividad.')
      this.router.navigate(['/login'])
    }
  }
}
