import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "@environments/environment";

@Injectable()
export class ExportService {
  constructor(private httpClient: HttpClient) {}

  public exportToExcel(data: any[], fileName: string): void {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data)

    const workbook: XLSX.WorkBook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, ws, 'Sheet1')
    XLSX.writeFile(workbook, `${fileName}.xlsx`)
  }

  ExportExcelget(){
    return this.httpClient.get('https://rlpo4vg3p9.execute-api.us-east-1.amazonaws.com/reporteMatriculas')

  }
  public exportToCsv(data: any[], fileName: string) {
    const replacer = (key, value) => (value === null ? '' : value)
    const header = Object.keys(data[0])
    let csv = data.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(','),
    )
    csv.unshift(header.join(','))
    let csvArray = csv.join('\r\n')
    var blob = new Blob([csvArray], { type: 'text/csv' })
    FileSaver.saveAs(blob, fileName + '.csv')
  }
}
