import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, EventEmitter, Output } from '@angular/core';
import { environment } from '@environments/environment';
import { IChildProfile } from '@interfaces/child-profile.interface';
import { IChildrenPayload } from '@interfaces/children-payload.interface';
import { Almuerzo } from '@models/almuerzo';
import { Child } from '@models/child';
import { Ruta } from '@models/ruta';
import { ChildPayload } from '@payloads/child.payload';
import { IAlmuerzoResponse } from '@server-responses/almuerzo-response.interface';
import { IChildrenResponse } from '@server-responses/children-response.interface';
import { IRutasResponse } from '@server-responses/rutas-response.interface';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { DatatableComponent } from '../components/datatable/datatable.component';

@Injectable({
  providedIn: 'root',
})
export class ChildrenService {
  @Output() propagar: EventEmitter<any> = new EventEmitter();
  constructor(
    private httpClient: HttpClient,
    private cookieService: CookieService
  ) { }

  private childUpdatedSource = new BehaviorSubject<IChildProfile>(null);
  private childrenUpdatedSource = new BehaviorSubject<IChildProfile[]>(null);

  public childUpdated = this.childUpdatedSource.asObservable();
  public childrenUpdated = this.childrenUpdatedSource.asObservable();

  childUpdate(child: IChildProfile) {
    this.childUpdatedSource.next(child);
  }

  childrenUpdate(children: IChildProfile[]) {
    this.childrenUpdatedSource.next(children);
  }

  checkForExistingLead(childrenId: string) {
    return this.httpClient.get(
      `${environment.API}/children/${childrenId}/existed_lead`
    );
  }

  downloadPdf() {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    const fileId = '1619474102243.pdf';
    return this.httpClient.get(`${environment.API}/children/${fileId}`, {
      headers: headers,
      responseType: 'blob' as 'json',
    });
  }

  /**
   * @update Adrian Avendano Concha
   * @date 17/01/2022
   */
  getChildren() {
    return this.httpClient.get(`${environment.API}/children`).pipe(
      map((response: IChildrenResponse) => {
        return response.children.map((child: IChildrenPayload) => {
          return new Child(child);
        });
      })
    );
  }

  getChildrenScreening(childId: string) {
    return this.httpClient.get(
      `${environment.API}/children/${childId}/screening`
    );
  }

  /**
   * @author Adrian Avendano Concha
   * @date 03/03/2022
   */
  getChildrenScreeningLambda(childId: string) {
    return this.httpClient.get(
      `${environment.API3}/AolChildScreening?child_id=${childId}`
    );
  }
  getPaymentNow(childId: string) {
    //return this.httpClient.get(`https://rlpo4vg3p9.execute-api.us-east-1.amazonaws.com/webhookEpayco?child_id=${childId}`)
    return this.httpClient.get(
      `https://rlpo4vg3p9.execute-api.us-east-1.amazonaws.com/webhookEpayco?child_id=${childId}`
    );
  }

  /**
   * @author Adrian Avendano Concha
   * @date 10/06/2022
   */
  getChildPaymentStatus(childId: string) {
    return this.httpClient.get(
      `${environment.API_ENDPOINTS}/aolservices/statuspayment?child_id=${childId}`
    );
  }

  saveScreening(childId: string, payload) {
    return this.httpClient.post(
      `${environment.API}/children/${childId}/screening`,
      payload
    );
  }

  updateChild(payload: ChildPayload, id: string) {
    return this.httpClient.put(
      `${environment.API}/children/${id}`,
      payload.toUpdateJSON()
    );
  }

  updateChild2(child_id: string, payload: any, accion: string) {
    //return this.httpClient.post(`https://rlpo4vg3p9.execute-api.us-east-1.amazonaws.com/OtherPersonalInfoChild?accion=${accion}&childId=${child_id}`, payload)
    return this.httpClient.post(
      ` https://4qhccy6pqi.execute-api.us-east-1.amazonaws.com/otherPersonalInfoChildTest?accion=${accion}&childId=${child_id}`,
      payload
    );
  }

  getInfoChild2(child_id: string) {
    //return this.httpClient.get(`https://rlpo4vg3p9.execute-api.us-east-1.amazonaws.com/OtherPersonalInfoChild?childId=` + child_id)
    return this.httpClient.get(
      ` https://4qhccy6pqi.execute-api.us-east-1.amazonaws.com/otherPersonalInfoChildTest?childId=` +
      child_id
    );
  }

  getInfoStepsEnrollment() {
    return this.httpClient.get(
      `https://rlpo4vg3p9.execute-api.us-east-1.amazonaws.com/enrollmentProcessesFlow`
    );
  }

  processStepEnrollmentConfirmated(payload: any) {
    return this.httpClient.post(
      `https://rlpo4vg3p9.execute-api.us-east-1.amazonaws.com/enrollmentProcessesFlow`,
      payload
    );
  }

  updateChildDocuments(childId: string, payload: any) {
    return this.httpClient.put(
      `${environment.API}/child-documents/${childId}`,
      payload
    );
  }

  /** @deprecated Use updateChildDocuments */
  uploadFile(payload: ChildPayload) {
    return this.httpClient.post(
      `${environment.API}/children/upload-file`,
      payload.toFormData()
    );
  }

  scholarService(childId: string, payload: ChildPayload) {
    return this.httpClient.post(
      `${environment.API}/children/${childId}/scholar-service/`,
      payload.updateAcademicInfo()
    );
  }

  verifyScreening(childId, screening_type) {
    return this.httpClient.post(
      `${environment.API}/children/${childId}/verify-screening`,
      {
        screening_type,
      }
    );
  }

  /**
   * Validar si ya existe el documento del estudiante.
   * @param document
   * @param documentType
   * @param childId
   * @author Adrian Avendano Concha
   * @date 11/01/2022
   */
  verifyDocument(child_id: string, doc: string, docType: string): any {
    let data = {
      childDocs: [
        {
          docType: docType,
          docNumber: doc,
          childId: child_id,
        },
      ],
    };
    return this.httpClient.post(`${environment.API3}/validateChildId`, data);
  }

  /**
  * Guardar la opcion que selecciono el usuario respecto a la autorizacion de imagen.
  * @param child_id
  * @param auth
  * @param type
  * @author Adrian Avendano Concha
  * @date 12/01/2022
  */
  updateImageAuth(child_id: string, user_id: string, auth: boolean, type: any): any {
    let data = {
      childId: child_id,
      userId: user_id,
      auth: auth,
      type: type
    };
    return this.httpClient.post(
      //`${environment.API3}/imageAuth`, data,
      `${environment.API2}/imageAuthTest`, data,
    )
  }

  /**
   * Crear una cookie con el index del child seleccionado.
   * @param index
   * @author Adrian Avendano Concha
   * @date 12/01/2022
   */
  /*setIndexChild(index: string) {
    this.cookieService.set(environment.SELECTED_CHILD_COOKIE, index, null, '/');
  }*/

  /**
   * @autor Adrian Avendano Concha
   * @date 11/02/2022
   */
  getStatusContract(childId: string, serviceType: string) {
    let data = {
      childId: childId,
      service_type: serviceType,
    };
    return this.httpClient.post(
      `${environment.API3}/testAolIsContractSigned`,
      data
    );
  }

  /**
   * @autor Adrian Avendano Concha
   * @date 14/02/2022
   */
  getAlmuerzoAmount(childId: string) {
    let url = `${environment.API3}/AolShowAlimentacionChild`;
    url += `?childId=${childId}`;
    return this.httpClient.get(url).pipe(
      map((response: any) => {
        return response.map((alumuerzo: IAlmuerzoResponse) => {
          return new Almuerzo(alumuerzo);
        });
      })
    );
  }

  /**
   * @autor Adrian Avendano Concha
   * @date 14/02/2022
   */
  getRoutesBySchool(childId: string) {
    let url = `${environment.API3}/AolShowRutasPerSchool`;
    url += `?childId=${childId}`;
    return this.httpClient.get(url).pipe(
      map((response: IRutasResponse[]) => {
        return response.map((ruta: IRutasResponse) => {
          return new Ruta(ruta);
        });
      })
    );
  }

  /**
   * @autor Adrian Avendano Concha
   * @date 11/02/2022
   */
  uploadRutaSelection(childId: string, rutaId: string, rutaType: string) {
    let data = {
      ruta_id: rutaId,
      tipo_ruta: rutaType,
    };
    let url = `${environment.API3}/AolSaveRutaSelection`;
    url += `?childId=${childId}`;
    return this.httpClient.post(url, data);
  }

  /**
   * @autor Alex Felipe Carvajal
   * @date 11/14/2022
   */
  saveFichaMedica(data: any) {
    return this.httpClient.post(
      `https://r7fe8z09si.execute-api.us-east-1.amazonaws.com/student-ficha-medica`,
      data
    );
  }

  saveProccesOmnicanalidad(data: any){
    return this.httpClient.post(
      `https://0x7owuhf4g.execute-api.us-east-1.amazonaws.com/Prod/validateOmnicanalidad`,
      data
    );
  }

  saveFinishProccesOmnicanalidad(data: any){
    return this.httpClient.post(
      `https://0x7owuhf4g.execute-api.us-east-1.amazonaws.com/Prod/validateOmnicanalidad`,
      data
    );
  }

  anulateServices(data: any){
    return this.httpClient.post(
      `https://0x7owuhf4g.execute-api.us-east-1.amazonaws.com/Prod/validateOmnicanalidad`,
      data
    );
  }

  getFichaMedica(childId: string) {
    return this.httpClient.get(
      `https://r7fe8z09si.execute-api.us-east-1.amazonaws.com/student-ficha-medica?childId=${childId}`
    );
  }

  deleteFichaMedica(medicalRecordId: string) {
    return this.httpClient.delete(
      `https://r7fe8z09si.execute-api.us-east-1.amazonaws.com/student-ficha-medica?medicalRecordId=${medicalRecordId}`
    );
  }

  /**
   * @autor Adrian Avendano Concha
   */
  getSchoolByChild(child_id: string) {
    return this.httpClient.get(
      `${environment.API_ENDPOINTS}/aolservices/pipelines?child=${child_id}`
    );
  }

  /**
   * @autor Adrian Avendano Concha
   */
  generatePayment(form: any) {
    let url = `${environment.API_ENDPOINTS}/aolservices/payments`;
    return this.httpClient.put(url, form).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  /**
   * Validar si tiene pagos adicionales pendientes
   * @autor Adrian Avendano Concha
   * @date 04/11/2022
   */
  validateAdtionals(child_id: string, type: string) {
    let url = `${environment.API_ENDPOINTS}/aolutilities/services?child_id=${child_id}&${type}=true`;
    return this.httpClient.get(url).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  /**
   * Validar si el estudiante aplica para renovacion
   * @autor Adrian Avendano Concha
   * @date 04/11/2022
   */
  validateRenovation(child_id: string) {
    let url = `${environment.API_ENDPOINTS}/aolutilities/renovation?child=${child_id}`;
    return this.httpClient.get(url).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  validateOmnicanalidad(child_id: string){
    let url = `${environment.API_ENDPOINTS}/aolutilities/services?child_id=${child_id}&date=true`;
    return this.httpClient.get(url).pipe(
      map((response: any) => {
        return response;
      })
    );
  }



  validateCupos(child_id: any,school_id: string, grade_id: string){
    return this.httpClient.get(
      ` https://0x7owuhf4g.execute-api.us-east-1.amazonaws.com/Prod/validateOmnicanalidad?child_id=${child_id}&school_id=${school_id}&grade_id=${grade_id}`
    );
  }
  
  sendEmail(to: string,  plantillaCorreo?: string){
    let test = {'to': to, "from":"experienciapadres@innovaschools.edu.co",
    "subject":"Cambio de colegio", 'message':plantillaCorreo}
    return this.httpClient.post('https://rlpo4vg3p9.execute-api.us-east-1.amazonaws.com/SesEmailService', test)    
  }
}
