import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { SharedModule } from 'app/shared/shared.module';
import { RecoverPasswordModule } from './recover-password/recover-password.module';

const routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'create-account',
    loadChildren: () =>
      import('./create-account/create-account.module').then(
        (m) => m.CreateAccountModule
      )
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./login/login.module').then(
        (m) => m.LoginModule
      )
  },
  { path: 'admin', redirectTo: '/admin/login', pathMatch: 'full' },
  {
    path: 'admin/login',
    loadChildren: () =>
      import('./admin-login/admin-login.module').then(
        (m) => m.AdminLoginModule,
      ),
  },
  {
    path: 'recover-password',
    loadChildren: () =>
      import('./recover-password/recover-password.module').then(
        (m) => m.RecoverPasswordModule
      )
  },
  {
    path: 'password-recovery',
    loadChildren: () =>
      import('./password-recovery/password-recovery.module').then(
        (m) => m.PasswordRecoveryModule
      )
  },
  {
    path: 'admin/login/verify',
    loadChildren: () =>
      import('./verify/verify.module').then(
        (m) => m.VerifyModule
      )
  }
]

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    FuseSharedModule,
  ],
  providers: [],
  declarations: [
  ]
})
export class UnauthModule { }
