import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule, Routes } from '@angular/router';

import { AdminGuard } from './shared/guards/admin-guard.service';
import { AuthGuard } from './shared/guards/auth-guard.service';
import { UnauthGuard } from './shared/guards/unauth-guard.service';

const appRoutes: Routes = [
  {
    path: '',
    loadChildren: './main/unauth/unauth.module#UnauthModule',
    canActivate: [UnauthGuard],
  },
  {
    path: 'admin',
    loadChildren: './main/admin/admin.module#AdminModule',
    canActivate: [AdminGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(appRoutes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
