import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { Child } from '@models/child';

@Injectable({
  providedIn: 'root',
})
export class StoreService {
  constructor() {}

  private selectedChild = new BehaviorSubject<Child>(null);

  selectedChild$ = this.selectedChild.asObservable();

  setChildSelected(selectedChild: Child): void {
    this.selectedChild.next(selectedChild);
  }
}
